var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.direction = site.direction || {};
var generic = generic || {};

(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $shadesContainer = $('.js-product-brief-shades-v2', $(this));
    var $swatchList = $('.js-filter-swatch-slider', $shadesContainer).length
      ? $('.js-filter-swatch-slider', $shadesContainer)
      : $('.js-product-brief-shades__grid', $shadesContainer);

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.productBriefShadesV2.selectSku($swatchList, skuBaseId);
    if (!$swatchList.parent().hasClass('js-product-brief-shades')) {
      $swatchList.resize();
    }
  });

  $(document).on('product.init', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v2', $(this));
    var $swatchList = $('.js-product-brief-shades__grid', $shadesContainer);

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.productBriefShadesV2.init($swatchList);
    var text = $(this).find('.js-shade-updating-message-count').text().split(':')[0];
    var $shadeCountContent = $('.js-sku-categories-content').find('.js-product-brief-shades-item');
    var shadeCount = $(this).find($shadeCountContent).length;

    text += ':' + shadeCount;
    if ($(this).parent().hasClass('js-quickshop__container')) {
      $swatchList.attr('aria-labelledby', 'js-shade-updating-message-count--' + shadeCount);
      $swatchList
        .parent()
        .find('.js-shade-updating-message-count')
        .attr('id', 'js-shade-updating-message-count--' + shadeCount);
    }
    $(this).find('.js-shade-updating-message-count').empty().append(text);
  });

  $(document).on('inv_status_display:updated', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v2', $(this));
    var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

    $swatchLists.each(function () {
      var $swatchList = $(this);
      var $swatches = $('.js-product-brief-shades-item-link', $swatchList);

      $swatches.each(function () {
        var $swatchLink = $(this);
        var skuBaseId = $swatchLink.data('skuBaseId');
        var skuData = prodcat.data.getSku(skuBaseId);

        Drupal.behaviors.productBriefShadesV2.inventoryCheck($swatchLink, skuData);
      });
    });
  });

  // Display default shade pre-selected in cross sell section
  $(document).on('inv_status_data:updated', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v2', $(this));
    var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

    $swatchLists.each(function () {
      var $swatchList = $(this);

      Drupal.behaviors.productBriefShadesV2.selectFirstSku($swatchList);
    });
  });

  $(document).on('grid.activeFilter', function (e, activeFilters) {
    var $swatchList = $(
      '.js-product:visible .js-product-brief-shades-v2 .js-product-brief-shades-item',
      $(this)
    );

    if (Drupal.behaviors.productBriefShadesV2.useGridLayout) {
      Drupal.behaviors.productBriefShadesV2.rebuildShadesGridOnActiveFilter(
        $swatchList,
        activeFilters
      );
    } else {
      Drupal.behaviors.productBriefShadesV2.rebuildShadesSliderOnActiveFilter(
        $swatchList,
        activeFilters
      );
    }
  });

  $(document).on('grid.reflow', function () {
    $('.js-filter-swatch-slider').each(function () {
      // we initialize the filter carousels only when the item is fully visible else
      // the sliders doesn't load right due to item being hidden
      Drupal.behaviors.productBriefShadesV2.initializeSlick($(this));
    });
  });

  $(document).on('grid.reset.filters', function () {
    // remove all the filter sliders on filter reset
    $('.js-filter-swatch-slider', $(this)).remove();
    $('.slick-slider', $(this)).removeClass('hidden');
    if (Drupal.behaviors.productBriefShadesV2.useGridLayout) {
      var $shadesContainer = $('.js-product-brief-shades-v2', $(this));
      var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

      $swatchLists.each(function () {
        var $swatchList = $(this);

        Drupal.behaviors.productBriefShadesV2.initializeGrid($swatchList);
      });
    }
  });

  $(document).on('grid.carousel.visible', function (e, data) {
    // If the grid is set as infinite carousel we need to listen for the load event
    // and initialize the swatches slick from the cloned items as they only get the
    // html from the items they clone, they don't get the slick functionality
    var $carousel = data.gridCarousel;
    var $clonedSlides = $carousel.find('.slick-cloned.js-product-grid-item');

    if ($clonedSlides.length > 0) {
      $clonedSlides.each(function () {
        var $clonedSlide = $(this);
        var $swatchList = $('.js-product-brief-shades__grid:not(.reloaded)', $clonedSlide);

        if ($swatchList.length > 0) {
          Drupal.behaviors.productBriefShadesV2.reinitShadeCarousel($swatchList);
        }
      });
    }
  });

  $(document).on('grid.carousel.lazyloaded', function (e, data) {
    var $carousel = data.gridCarousel;
    var selector = data.selector || '.js-product-grid-item';
    var $swatchListActiveSlides = $(
      selector + ' .js-product-brief-shades__grid:not(.reloaded)',
      $carousel
    );

    if ($swatchListActiveSlides.length > 0) {
      $swatchListActiveSlides.each(function () {
        Drupal.behaviors.productBriefShadesV2.reinitShadeCarousel($(this));
      });
    }
  });

  $('.js-product-brief-shades-item-link').on('keydown', function (e) {
    if (site.getKeycode(e) === 13) {
      $(this).trigger('click');
    }
  });

  Drupal.behaviors.productBriefShadesV2 = {
    isMobile: false,
    useGridLayout: false,
    attached: false,
    attach: function (context) {
      var self = this;
      var $shadesContainer = $('.js-product-brief-shades-v2', context);
      var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

      if (self.attached) {
        return;
      }
      self.attached = true;
      var bp = '';

      self.isMobile = (bp = Unison.fetch.now().name) === 'small';
      self.init($swatchLists);
      self.setEvents(context);

      Unison.on('change', function (cbp) {
        self.isMobile = cbp.name === 'small';
        if (bp !== cbp.name && (cbp.name === 'small' || bp === 'small')) {
          self.init($swatchLists);
          self.setEvents(context);
          bp = cbp.name;
        }
      });
    },

    setEvents: function (context) {
      var self = this;
      // Select first shade if default sku isn't in stock
      $(function() {
        var $briefShades = $('.js-product-brief-shades-v2', context);
        var $shadesGrid = $('.js-product-brief-shades__grid', $briefShades);
        var $shadesGridItem = $('.js-product-brief-shades-item', $shadesGrid);
        var $items = $shadesGrid.find('.js-product-brief-shades-item');
        $items.once().each(function () {
          var $this = $(this);
          var $product = $this.closest('.js-product-brief');
          var $firstShade = $product.find('.js-product-full-shades-grid-item').first();
          var $shadesGridLink = $('.js-product-brief-shades-item-link', $this);
          var $skuBaseId = $this.data('sku-base-id');
          var $invStatus = $this.data('inventory-status');
          var $defaultStatus = $this.data('default-sku');

          if ($invStatus !== 1) {
            if ($shadesGridLink.hasClass('selected') || $defaultStatus === 1 ) {
            $skuBaseId = $this.parent().find($shadesGridItem).first().data('sku-base-id');
            $product.trigger('product.skuSelect', $skuBaseId).once();
            }
          }
        });
      });
      // only bind the visible items, the hidden will get rebound on parent show / load
      $('.js-product-brief-shades-item-link', context)
        .once('js-shdes-carousel-link')
        .on('click', function (e) {
          e.preventDefault();
          self.selectShade($(this));
        })
        .on('mouseover', function () {
          var $this = $(this);

          if ($this.closest('.slick-slider.js-product-brief-shades__grid').length > 0) {
            return;
          }
          var skuBaseId = $this.data('sku-base-id');
          var skuData = prodcat.data.getSku(skuBaseId);

          if (!!skuData) {
            var $thisProd = $this.closest('.js-product');
            var $prodImage = $thisProd.find('.js-product-brief-image');
            var $prodImageAlt = $thisProd.find('.js-product-brief-image-alt');
            var $prodShadesHeaderWrapper = $thisProd.find(
              '.js-product-brief-shades__header-wrapper:visible'
            );
            var $prodShadesHeader = $thisProd.find('.js-product-brief-shades__header:visible');
            var $prodShadesBadge = $thisProd.find('.js-product-brief-shades__badge');
            var $prodShadesName = $thisProd.find('.js-product-brief-shades__name');
            var $prodShadesDescription = $thisProd.find('.js-product-brief-shades__description');
            var skuBadge = skuData.hasOwnProperty('MISC_FLAG_TEXT') ? skuData.MISC_FLAG_TEXT : '';
            var shadeName = skuData.hasOwnProperty('SHADENAME') ? skuData.SHADENAME : '';
            var shadeDescription = skuData.hasOwnProperty('SHADE_DESCRIPTION')
              ? skuData.SHADE_DESCRIPTION
              : '';

            if ($prodShadesHeaderWrapper[0].style.height === '') {
              $prodShadesHeaderWrapper.height($prodShadesHeader.height());
            }

            $prodShadesBadge.html(skuBadge);
            $prodShadesName.html(shadeName);
            $prodShadesDescription.html(shadeDescription);

            if ($prodShadesHeaderWrapper.height() < $prodShadesHeader.height()) {
              $prodShadesHeaderWrapper.height($prodShadesHeader.height());
            }

            if ($prodImage.hasClass('lazyload')) {
              // if the image was not loaded yet but has lazyload we need to update only data-src lazyload will set the src
              if (
                !!skuData.IMAGE_MEDIUM_COMBINED &&
                skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('data-src')
              ) {
                $prodImage.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
                $prodImageAlt.attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
              }
            } else {
              // if the image was already loaded by lazyload or lazyload is not used on this image
              // we need to update both data-src and src
              if (
                !!skuData.IMAGE_MEDIUM_COMBINED &&
                skuData.IMAGE_MEDIUM_COMBINED[0] !== $prodImage.attr('src')
              ) {
                $prodImage
                  .attr('src', skuData.IMAGE_MEDIUM_COMBINED[0])
                  .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[0]);
                $prodImageAlt
                  .attr('src', skuData.IMAGE_MEDIUM_COMBINED[1])
                  .attr('data-src', skuData.IMAGE_MEDIUM_COMBINED[1]);
              }
            }
            self.updateProdImageSrcsets($prodImage, $prodImageAlt, skuData);
          }
        })
        .on('mouseleave', function () {
          var $this = $(this);

          if ($this.closest('.slick-slider.js-product-brief-shades__grid').length > 0) {
            return;
          }

          var $selectedShade = $this.closest('.js-product-brief-shades').find('.selected');
          self.selectShade($selectedShade);
        });

      // fail check for mixitup grid, the slick doesn't load properly if the parent
      // of the slick is a mixitup item and the grid loads while window is out of focus
      // so we have to reinit all of them
      $('.js-product-results-wrapper .js-product-grid__wrapper', context)
        .once('js-product-shades-loaded')
        .on('mixEnd', function () {
          var $grid = $(this);

          if ($grid.hasClass('js-product-shades-loaded-processed')) {
            // run only once per page load
            $grid.removeClass('js-product-shades-loaded-processed');
            var $swatchLists = $('.js-product-brief-shades__grid', $grid);

            $swatchLists.each(function () {
              self.initializeSlick($(this));
            });
          }
        });
    },

    selectShade: function ($swatchLink) {
      var selectedSkuBaseId = $swatchLink.attr('data-sku-base-id');
      var selectedProductId = $swatchLink.attr('data-product-id');
      var $productEl = $(".js-product[data-product-id='" + selectedProductId + "']");
      $productEl.trigger('product.skuSelect', selectedSkuBaseId);

      if ($swatchLink.hasClass('selected')) {
        $swatchLink.attr({
          'aria-selected': 'true',
          'aria-describedby': 'js-shade-updating-message'
        });
      }
    },

    updateProdImageSrcsets: function ($prodImage, $prodImageAlt, skuData) {
      var isProductImageResponsive =
        Drupal.settings.global_variables &&
        Drupal.settings.global_variables.product_responsive_images;
      var baseImagePath;
      var baseImageAltPath;
      var imageSrcset;
      var imageAltSrcset;
      var productBriefImageSrcsetSizes = [
        {
          size: '310',
          xDescriptor: '310w',
        },
        {
          size: '768',
          xDescriptor: '768w',
        },
      ];
  
      if (isProductImageResponsive) {
        baseImagePath = skuData.IMAGE_MEDIUM_COMBINED[0] && skuData.IMAGE_MEDIUM_COMBINED[0].split('?')[0];
        baseImageAltPath = skuData.IMAGE_MEDIUM_COMBINED[1] && skuData.IMAGE_MEDIUM_COMBINED[1].split('?')[0];
        imageSrcset = '';
        imageAltSrcset = '';
        productBriefImageSrcsetSizes.forEach(imageSrcsetSize => {
          imageSrcset += `${baseImagePath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
          imageAltSrcset += `${baseImageAltPath}?width=${imageSrcsetSize.size}&height=${imageSrcsetSize.size} ${imageSrcsetSize.xDescriptor},`;
        });
        imageSrcset = imageSrcset.slice(0, -1);
        imageAltSrcset = imageAltSrcset.slice(0, -1);
  
        if ($prodImage.hasClass('lazyload')) {
          $prodImage.attr('data-srcset', imageSrcset);
          $prodImageAlt.attr('data-srcset', imageAltSrcset);
        } else {
          $prodImage
            .attr('srcset', imageSrcset)
            .attr('data-srcset', imageSrcset);
          $prodImageAlt
            .attr('srcset', imageAltSrcset)
            .attr('data-srcset', imageAltSrcset);
        }
      }
    },

    init: function ($swatchLists) {
      var self = this;
      var cr24_plp = !Drupal?.settings?.global_variables?.cr24_plp ? false : true;

      if (!self.attached) {
        // wait for attach, product.init triggers before attach and breaks the logic
        return;
      }
      if (cr24_plp === false) {
        self.useGridLayout = !self.isMobile;
      }
      $swatchLists.each(function () {
        var $swatchList = $(this);
        var $swatches = $('.js-product-brief-shades-item-link', $swatchList);
        // Select first sku
        var $contextProductEl = prodcat.ui.getContextProductEl($swatchList);

        if (!$contextProductEl.data('default-shade-unselected')) {
          self.selectFirstSku($swatchList);
        }

        // add gray border to light shades (lighter than  R: 233 G: 223 B: 245 or hex #E9DFF5)
        $swatches.each(function () {
          var $swatchLink = $(this);
          var rgbStr = $swatchLink.css('backgroundColor');
          var matches = rgbStr.match(/(\d*),\s(\d*),\s(\d*)/);

          // IE8 returns a hex value; other browsers return rgb. We're not drawing outlines in IE8 anyway, so return.
          if (!matches) {
            return false;
          }
          var red = parseInt(matches[1]);
          var green = parseInt(matches[2]);
          var blue = parseInt(matches[3]);

          if (red > 233 && green > 223 && blue > 245) {
            $swatchLink.addClass('light-shade');
          }
        });
        self.initializeSlick($swatchList);
        $swatchList.on('resetSwatchList', function () {
          self.resetSwatchList($swatchList);
        });
        $swatchList.on('selectSwatch', function (e, skuBaseId) {
          self.selectSku($swatchList, skuBaseId);
        });
      });
    },

    inventoryCheck: function ($swatchLink, skuData) {
      var self = this;
      var classPrefix = 'shade-inventory-status--';

      if (!!skuData) {
        var loadedInventoryStatus = parseInt($swatchLink.data('inventoryStatus'));
        var L2InventoryStatus = parseInt(skuData.INVENTORY_STATUS);
        var $shadeItem = $swatchLink.closest('.js-product-brief-shades-item');
        var $shadeGrid = $shadeItem.closest('.js-product-brief-shades__grid');
        var $sliderList =
          $shadeGrid.find('.slick-track').length > 0 ? $shadeGrid.find('.slick-track') : $shadeGrid;
        var enableReorder = $sliderList.find('.js-product-brief-shades-item').length > 1;

        // If the updated L2 is the same status. Nothing to update.
        if (!_.isUndefined(loadedInventoryStatus) && L2InventoryStatus === loadedInventoryStatus) {
          if (enableReorder && L2InventoryStatus !== 1) {
            self.moveAtTheEnd($sliderList, $shadeItem);
          }

          return;
        }

        if (!!loadedInventoryStatus) {
          $swatchLink.removeClass(classPrefix + loadedInventoryStatus);
        }
        $swatchLink
          .addClass(classPrefix + L2InventoryStatus)
          .data('inventoryStatus', L2InventoryStatus)
          .attr('data-inventory-status', L2InventoryStatus);
        if (enableReorder && L2InventoryStatus !== 1) {
          self.moveAtTheEnd($sliderList, $shadeItem);
        }
      }
    },

    moveAtTheEnd: function ($shadesContainer, $shadeItem) {
      $shadesContainer.append($shadeItem);
    },

    initializeGrid: function ($swatchList) {
      var self = this;

      if (!self.useGridLayout) {
        return;
      }
      self.resetSwatchListLayout($swatchList);
      var $shadesContainer = $swatchList
        .closest('.js-product-brief-shades')
        .attr('aria-expended', false);
      var $shades = $('.js-product-brief-shades-item', $swatchList);

      $swatchList.addClass('product-brief-shades__grid--layout');
      $shades.removeClass('hidden');
      self.setEvents($swatchList);
    },

    resetSwatchListLayout: function ($swatchList) {
      var self = this;

      if ($swatchList.hasClass('slick-initialized') && self.useGridLayout) {
        $swatchList.unslick();
      }
      if ($swatchList.hasClass('product-brief-shades__grid--layout') && !self.useGridLayout) {
        $swatchList.removeClass('product-brief-shades__grid--layout');
      }
    },

    checkSelected: function ($swatchList) {
      var self = this;
      var $selectedShade = $('.js-product-brief-shades-item-link.selected', $swatchList);

      if ($selectedShade.parent().hasClass('hidden')) {
        $selectedShade.removeClass('selected');
        self.selectFirstSku($swatchList);
      }
    },

    reinitShadeCarousel: function ($swatchList) {
      var self = this;
      var $swatches = $(
        '.js-product-brief-shades-item:not(.slick-cloned)',
        $swatchList
      ).removeClass('slick-slide');

      $swatchList.empty().removeClass('slick-initialized').append($swatches).addClass('reloaded');
      // make sure the event get's added back, only the once class get's transfered on clone losing the event itself
      $('.js-product-brief-shades-item-link', $swatchList).removeClass(
        'js-shdes-carousel-link-processed'
      );
      self.setEvents($swatchList);
      self.initializeSlick($swatchList);
    },

    initializeSlick: function ($swatchList) {
      var self = this;
      var $slider = $swatchList;
      var initialSlide = $slider.data('initialSlide') || 0;
      var totalSlides = $swatchList.find('.js-product-brief-shades-item').length;

      if (self.useGridLayout) {
        self.initializeGrid($swatchList);

        return;
      }

      self.resetSwatchListLayout($swatchList);

      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        autoplay: false,
        centerMode: false,
        slidesToShow: 7,
        slidesToScroll: 7,
        initialSlide: initialSlide,
        swipe: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 7,
              slidesToScroll: 7,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          }
        ],
        onInit: function (_slick) {
          _slick.$slider.trigger('init', _slick);
        },
        onBeforeChange: function (_slick, _currentSlide, _nextSlide) {
          // _currentSlide = The slideIndex the carousel shows first ( this is not really the slide that is active)
          // _nextSlide = The slideIndex the carousel will land on after animation is done
          _slick.$slider.trigger('beforeChange', _slick, _currentSlide, _nextSlide);
        },
        onAfterChange: function (_slick, _curentSlide) {
          _slick.$slider.trigger('afterChange', _slick, _curentSlide);
        },
        onSetPosition: function (_slick) {
          _slick.$slider.trigger('afterSetPosition', _slick);
        },
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        slide: '.js-product-brief-shades-item'
      };

      function _slick_slider() {
        if (!$slider.hasClass('slick-initialized')) {
          return $slider.slick(settings);
        }
      }
      _slick_slider();
      $slider.on('afterSetPosition', function (e, _slick) {
        if (_slick.slideWidth) {
          if (totalSlides === 1 && !_slick.$slideTrack.hasClass('js-forced-width')) {
            var maxWidth = $(_slick.$slides[0])
              .find('.js-product-brief-shades-item-link')
              .outerWidth(true);

            _slick.$slideTrack.css({ width: maxWidth }).addClass('js-forced-width');
          }
          _slick.$slides.removeClass('hidden');
          // the initial setRvents bind only to visible items
          self.setEvents();
        }
      });

      if (totalSlides <= 7) {
        $slider.addClass('product-brief-shades__no-carousel');
      }
    },

    rebuildShadesGridOnActiveFilter: function ($swatchList, activeFilters) {
      var self = this;
      var $shadesContainer = $();
      var $shadesContainers = $();
      var filterString = '';
      var $activeShades = $();

      if (!$.isEmptyObject(activeFilters) && activeFilters.skuFilters.length > 0) {
        filterString = activeFilters.skuFilters.join(', ');
        $activeShades = $swatchList.filter(filterString);
        $swatchList.removeClass('hidden').not($activeShades).addClass('hidden');
        $shadesContainers = $activeShades.closest('.js-product-brief-shades');
        $shadesContainers.each(function () {
          $shadesContainer = $(this);
          self.checkSelected($shadesContainer);
        });
      } else {
        $swatchList.removeClass('hidden');
      }
    },

    rebuildShadesSliderOnActiveFilter: function ($swatchList, activeFilters) {
      $swatchList.closest('.slick-slider').removeClass('hidden');
      if ($swatchList.length < 1) {
        return null;
      }
      // remove all the filter sliders on filter
      $('.js-filter-swatch-slider').remove();
      if (!$.isEmptyObject(activeFilters) && activeFilters.skuFilters.length > 0) {
        var filterString = activeFilters.skuFilters.join(', ');
        var $sliders = $swatchList.filter(filterString).closest('.slick-slider');

        $.each($sliders, function () {
          $(this).addClass('hidden');
          Drupal.behaviors.productBriefShadesV2.initializeFilterSlick(
            $(this),
            $(this).find($swatchList).filter(filterString)
          );
        });
      } else {
        $swatchList.removeClass('hidden');
      }
    },

    initializeFilterSlick: function ($slider, $slides) {
      var self = this;

      $slides = $slides.clone(true).addClass('hidden');

      var $sliderFilterClone = $slider
        .clone(true)
        .unslick()
        .removeClass('slick-initialized')
        .addClass('js-filter-swatch-slider');

      $sliderFilterClone.data('initialSlide', 0).attr('data-initial-slide', 0);
      // clone the original slider and repopulate only with
      // the shades that match the filter
      // we remove the original slick html
      $sliderFilterClone.find('.slick-list').remove();
      $sliderFilterClone.find('.slick-prev').remove();
      $sliderFilterClone.find('.slick-next').remove();
      $slides.css({ width: '' });
      $sliderFilterClone.append($slides);
      $sliderFilterClone.removeClass('hidden');

      if ($slider.parent().find('.js-filter-swatch-slider').length < 1) {
        // clone doesn't keep the slick from the object so we have to
        // initialize the slider on the newly created slider
        $sliderFilterClone.insertAfter($slider);
        self.selectShade($slides.eq(0).find('.js-product-brief-shades-item-link'));
      }
    },

    selectFirstSku: function ($swatchList) {
      var self = this;
      // based on the list visibility we select the firstSKu
      var isVisible = $swatchList.closest('.product-grid__item').is(':visible');
      var $links = isVisible
        ? $(
            '.js-product-brief-shades-item:not(.hidden) .js-product-brief-shades-item-link',
            $swatchList
          )
        : $('.js-product-brief-shades-item-link', $swatchList);
      var firstSkuBaseId = '';
      var $firstSelectedShade = $();
      var prodId = $links.data('product-id');
      var $selectedSlide = $();

      // return if any already selected ie. Shade Router
      if ($links.hasClass('selected')) {
        return null;
      }
      var $swatchLi = $('.js-product-brief-shades-item', $swatchList);

      if ($swatchLi.hasClass('js-default-sku-select_' + prodId)) {

        if (isVisible) {
          $firstSelectedShade = $('.js-default-sku-select_' + prodId, $swatchList).is(':visible')
            ? $('.js-default-sku-select_' + prodId, $swatchList)
            : $links.first();
        } else {
          $firstSelectedShade = $('.js-default-sku-select_' + prodId, $swatchList);
        }
        firstSkuBaseId = $firstSelectedShade.data('sku-base-id');
      } else {
        $firstSelectedShade = $links.first();
        firstSkuBaseId = $firstSelectedShade.data('sku-base-id');
      }
      if (!!firstSkuBaseId) {
        $selectedSlide = $(
          '.js-product-brief-shades-item-link[data-sku-base-id="' + firstSkuBaseId + '"]',
          $swatchList
        ).addClass('selected');
        self.setSlickInitialSlide($swatchList, $selectedSlide);
        self.selectShade($firstSelectedShade);
      }
    },

    selectSku: function ($swatchList, skuBaseId) {
      var self = this;

      $swatchList.data('sku-base-id', skuBaseId);
      var $links = $('.js-product-brief-shades-item-link', $swatchList);

      $links.removeClass('selected');
      var $selectedSwatch = $(
        ".js-product-brief-shades-item-link[data-sku-base-id='" + skuBaseId + "']",
        $swatchList
      ).addClass('selected');

      self.setSlickInitialSlide($swatchList, $selectedSwatch);
    },

    setSlickInitialSlide: function ($swatchList, $selectedSlide) {
      var selectedIndex = $('.js-product-brief-shades-item-link', $swatchList).index(
        $selectedSlide
      );

      if (selectedIndex > 0) {
        $swatchList.data('initialSlide', selectedIndex).attr('data-initial-slide', selectedIndex);
      }
    },

    resetSwatchList: function ($swatchList) {
      var $links = $('.js-product-brief-shades-item-link', $swatchList);

      $links.removeClass('selected');
    }
  };
})(jQuery);
